import { DataCell } from "components/DataGrid/DataCell/DataCell";
import { HeaderCell } from "components/DataGrid/Header/HeaderCell";
import { handleScroll } from "helpers/Functions";
import React, { useEffect, useState } from "react";
import { Virtuoso } from "react-virtuoso";
import angleLeftSVG from "../../../assets/media/icons/standard_icons/angle_left.svg";
import angleRightSVG from "../../../assets/media/icons/standard_icons/angle_right.svg";
import { IKTSVG } from "../../../components/ui/IKTSVG";

// custom style  table
const Table = ({ style, ...props }: any) => (
  <table
    {...props}
    className="w-full text-xs text-left  min-w-[87.5rem]"
    style={{
      ...style,
    }}
  />
);

// custom style table head
const TableHead = ({ style, ...props }: any) => (
  <thead
    {...props}
    className="text-xs text-gray-400 border_th"
    style={{
      ...style,
    }}
  />
);

// table horizontal scroll  action
const scroll = (scrollOffset: any, setIsSettled: Function) => () => {
  setIsSettled(true);
  let replaceTable: any = document.getElementById("tableVirtuosoWrap");
  replaceTable && (replaceTable.scrollLeft += scrollOffset);
};

export const ListingTable = (props: any) => {
  const {
    data,
    columns,
    paginateData,
    setFilters,
    orderState,
    setOrderState,
    rowSelection = false,
    withErros = false,
    perPage = 20,
    height,
    isLoading,
    onEndReach,
    isDataFetched,
  } = props;

  const [paginateData1, setPaginateData] = useState(paginateData);
  useEffect(() => {
    if (paginateData) {
      setPaginateData(paginateData);
    }
  }, [paginateData]);

  const scroll = (scrollOffset: number) => {
    const load = document.getElementById(
      `listing-table-virtuoso`
    ) as HTMLElement | null;
    const loadHead = document.getElementById(
      `ListingtableDivHead`
    ) as HTMLElement | null;

    if (load && loadHead) {
      load.scrollLeft += scrollOffset;
      loadHead.scrollLeft += scrollOffset;
    } else {
    }
  };

  useEffect(() => {
    const stickyElement: any = document.getElementById(
      "topFilters"
    ) as HTMLElement;
    const HeadSticky = function () {
      const stickyShadow: any = document.getElementById("sticky-shadow");
      const ListingtableDivHeadL: any = document.getElementById(
        "ListingtableDivHead"
      ) as HTMLElement;
      const ListingtableDivHeadHeight: any = ListingtableDivHeadL?.clientHeight;
      const initialPosition: any = stickyElement.getBoundingClientRect().top;
      const HeaderHeight: any = document.querySelector("header")?.clientHeight;
      const filters_block: any =
        document.getElementById("filters_block")?.clientHeight;
      const trackAccordion: any =
        document.getElementById("trackAccordion")?.clientHeight;

      const EventAccordion = document.getElementById(
        "EventAccordion"
      ) as HTMLElement;

      const TopAccor: any = document.querySelector(".top-accor");

      var TopAccorcomputedStyle = window.getComputedStyle(TopAccor);
      var TopAccorMarginBottom = TopAccorcomputedStyle.marginTop;
      var TopAccorMarginBottomValue = parseInt(TopAccorMarginBottom, 10);

      const topFilters: any =
        document.getElementById("topFilters")?.clientHeight;
      const TopHeadPosition = topFilters - trackAccordion + HeaderHeight;
      const topShadPosition = TopHeadPosition + ListingtableDivHeadHeight;
      if (window.scrollY >= TopHeadPosition) {
        // ListingtableDivHeadL.classList.add("sticky");
        if (ListingtableDivHeadL) {
          ListingtableDivHeadL.style.top = `${TopHeadPosition}px`;
          ListingtableDivHeadL.style.position = "sticky";
          stickyShadow.style.top = `${topShadPosition}px`;
          stickyShadow?.classList.remove("!hidden");
        }
      } else {
        if (ListingtableDivHeadL) {
          ListingtableDivHeadL.style.position = "";
          ListingtableDivHeadL.style.top = 0;
          stickyShadow?.classList.add("!hidden");
        }
      }
    };
    const handleWindowResize = () => {
      HeadSticky(); // Update sticky behavior based on new values
    };

    const pmicons = document.querySelector(".PMicons button");

    window.addEventListener("scroll", HeadSticky);
    window.addEventListener("change", HeadSticky);
    window.addEventListener("blur", HeadSticky);
    pmicons?.addEventListener("click", function () {
      setTimeout(() => {
        HeadSticky();
      }, 100);
    });

    setTimeout(() => {
      document.addEventListener("resize", handleWindowResize);
    }, 200);
    return () => {
      window.removeEventListener("scroll", HeadSticky);
      window.removeEventListener("change", HeadSticky);
      window.removeEventListener("blur", HeadSticky);
      document.removeEventListener("resize", handleWindowResize);
      pmicons?.removeEventListener("click", HeadSticky);
    };
  });

  useEffect(() => {
    const div1: any = document.getElementById("ListingtableDivHead");
    const div2: any = document.getElementById("listing-table-virtuoso");

    function syncHorizontalScroll(event: any) {
      if (event.target === div1) {
        div2.scrollLeft = div1.scrollLeft;
      } else {
        div1.scrollLeft = div2.scrollLeft;
      }
    }

    if (div1 && div2) {
      div1.addEventListener("scroll", syncHorizontalScroll);
      div2.addEventListener("scroll", syncHorizontalScroll);
    }

    // Clean up the event listeners when the component unmounts
    return () => {
      if (div1 && div2) {
        div1.removeEventListener("scroll", syncHorizontalScroll);
        div2.removeEventListener("scroll", syncHorizontalScroll);
      }
    };
  }, []);

  // auto disabled left right table button
  useEffect(() => {
    const element = document.getElementById("listing-table-virtuoso");
    const stickyHead = document.getElementById("tableId1");

    const scrollHandler = () => handleScroll(element, stickyHead);
    if (element) {
      setTimeout(() => {
        scrollHandler(); // Initial check
      }, 100);
      element.addEventListener("scroll", scrollHandler);
      return () => {
        element.removeEventListener("scroll", scrollHandler);
      };
    }
  }, []); // Empty dependency array to run only once on

  return (
    <>
      <div className="accordion-body relative h-full ">
        <div
          className="scroll-smooth inventory-main inventory-table scrollbar-none scrollbar-thumb-violet-500 scrollbar-thumb-rounded-full rounded-b noscroll-x md:!h-full "
          id={`tableId1`}
        >
          <div
            className="w-full h-full text-xs text-left inv_table rounded !bg-white"
            id={`tableScroll`}
          >
            {/* <div> */}
            <div
              className="border-b text-xs text-gray-400 flex  tableHead overflow-auto scrollbar-none bg-white custom-head-below "
              id={`ListingtableDivHead`}
            >
              {!rowSelection && (
                <td className=" sticky left-0 ml-auto p-0 icon_td  border-b w-[1rem] pl-6"></td>
              )}

              {columns.map((column: any, index: any) =>
                typeof column?.title === "string" ? (
                  <React.Fragment key={index}>
                    <HeaderCell
                      key={column?.title}
                      title={column?.title}
                      width={column?.width}
                      arrow={column?.isArrow}
                      postKey={column?.postKey}
                      padding={column?.padding}
                      orderState={orderState}
                      setOrderState={setOrderState}
                    />
                  </React.Fragment>
                ) : (
                  column?.title
                )
              )}

              <div
                className={`max-md:opacity-0 max-md:pointer-events-none md:sticky right-0 ml-auto  p-0 w-[5.0625rem] min-w-[5.0625rem]`}
              >
                <div
                  className="py-[.4375rem] flex justify-center h-[2.8125rem] left-shad border-l bg-white"
                  id="left-shad-list"
                >
                  <button
                    className="group rounded-l  hover: transition px-[.5625rem] py-2"
                    id="leftArrow"
                    type="button"
                    onClick={() => scroll(-120)}
                  >
                    <IKTSVG
                      className="flex items-center justify-center fill-violet-500 transition group-hover:fill-indigo-500"
                      path={angleLeftSVG}
                      svgClassName="w-1.5 h-[.5625rem]"
                    />
                  </button>
                  <button
                    className="group rounded-r  transition px-[.5625rem] py-2"
                    id="rightArrow"
                    type="button"
                    onClick={() => scroll(120)}
                  >
                    <IKTSVG
                      className="flex items-center justify-center fill-violet-500 transition group-hover:fill-indigo-500"
                      path={angleRightSVG}
                      svgClassName="w-1.5 h-[.5625rem]"
                    />
                  </button>
                </div>
              </div>
            </div>
            {/* </div> */}
            <div
              className="tx-trade-table last-auto"
              style={{
                height: height,
                transition: "height ease-in-out",
              }}
            >
              <Virtuoso
                data={data}
                id={"listing-table-virtuoso"}
                increaseViewportBy={20}
                endReached={() => {
                  if (onEndReach) {
                    onEndReach();
                    return;
                  } else if (
                    paginateData1?.current_page < paginateData1?.last_page
                  ) {
                    setFilters((current: any) => {
                      let nextPage: number = paginateData1?.current_page + 1;
                      return {
                        ...current,
                        page: nextPage,
                        per_page: perPage,
                      };
                    });
                  }
                }}
                overscan={200}
                totalCount={data?.length}
                components={{
                  Footer: () => {
                    if (
                      (paginateData1?.current_page >= 2 &&
                        paginateData1?.current_page ===
                          paginateData1?.last_page &&
                        !onEndReach) ||
                      (onEndReach && isDataFetched)
                    ) {
                      return (
                        <div
                          className={`text-center text-sm13 py-3 px-2.5 bg-white border-t sticky left-0 top-0 w-[100vw] md:w-[calc(100vw-7.1875rem)]`}
                        >
                          No more items to show
                        </div>
                      );
                    } else if (isLoading) {
                      return (
                        <>
                          <div className="bg-gray-100 rounded mr-1 mb-2 w-full shimmer-effect append-child h-[1.875rem] !overflow-hidden"></div>
                          <div className="bg-gray-100 rounded mr-1 mt-1 mb-2 w-full shimmer-effect append-child h-[1.875rem]  !overflow-hidden"></div>
                          {/* <div className="bg-gray-100 rounded mr-1 mb-2 w-full shimmer-effect append-child h-[1.875rem]  !overflow-hidden"></div> */}
                        </>
                      );
                    } else {
                      return null;
                    }
                  },
                }}
                // useWindowScroll
                className="tableBody rounded-b"
                itemContent={(sIndex: any, list: any) => {
                  return (
                    <>
                      <React.Fragment key={sIndex}>
                        <div className="">
                          <div
                            key={sIndex}
                            className={`first_row border-b h-[2.5rem] items-center flex table_row ${
                              withErros &&
                              list?.errors &&
                              list?.errors?.length > 0 &&
                              "bg-[#FCEDF2]"
                            }`}
                          >
                            {!rowSelection && (
                              <div className="group sticky left-0 ml-auto icon_td  border-b w-[1rem] bg-white h-[2.8125rem] "></div>
                            )}

                            {columns.map((column: any, index: number) =>
                              typeof column?.title === "string" ? (
                                <React.Fragment key={index}>
                                  <DataCell
                                    key={column?.getValue(list) || index}
                                    value={column?.getValue(list)}
                                    classname="block"
                                    padding={column?.padding}
                                    width={column?.width}
                                  />
                                </React.Fragment>
                              ) : (
                                column?.getValue(list, sIndex)
                              )
                            )}

                            <div className="group sticky right-0 ml-auto p-0 icon_td min-w-[5rem] max-w-[5rem] h-full opacity-0">
                              {/* <div
                              className="flex relative h-full"
                              id="left-shad-list"
                            ></div> */}
                            </div>
                          </div>
                          {/* {withErros &&
                          Array?.isArray(list?.errors) &&
                          list?.errors?.length > 0 && (
                            <>
                              <div className="second_row flex !p-l-[2.5rem] w-full bg-gray-100/50 error-row-track">
                                <div className="p-0 equal-td-height  flex items-start  mb-4 sticky left-10">
                                  <div className="error-box border-b border-l border-r -mt-[1px] rounded-b row-error-new group-hover:bg-rose-550 bg-[#FCEDF2] false ">
                                    {Array?.isArray(list?.errors) &&
                                      list?.errors?.map((error: any) => {
                                        return (
                                          <div className="px-2.5 pb-[.3125rem] pt-0 flex text-rose-500">
                                            <span className="text-rose-500 text-xs inline-block font-semibold">
                                              error lime 1:&nbsp;&nbsp;
                                            </span>
                                            <p className="!text-gray-600 inline font-normal">
                                              Error line 2
                                            </p>
                                          </div>
                                        );
                                      })}
                                  </div>
                                </div>
                              </div>
                            </>
                          )} */}
                          {withErros &&
                            list?.errors &&
                            list?.errors?.length > 0 && (
                              <div className="second_row flex !p-l-[2.5rem] w-full bg-gray-100/50 error-row-track">
                                <div className="p-0 equal-td-height  flex items-start  mb-4 sticky left-10">
                                  <div className="error-box border-b border-l border-r -mt-[1px] rounded-b row-error-new group-hover:bg-rose-550 bg-[#FCEDF2] false ">
                                    <div className="px-2.5 pb-[.3125rem] pt-0 flex text-rose-500">
                                      <span className="text-rose-500 text-xs inline-block font-semibold">
                                        Error :&nbsp;&nbsp;
                                      </span>
                                      <p className="!text-gray-600 inline font-normal">
                                        {list?.errors}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )}
                        </div>
                      </React.Fragment>
                    </>
                  );
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
