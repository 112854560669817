export const StyledButton = ({
  btnType = "button",
  isDisabled = false,
  children,
  ...rest
}: any) => (
  <button
    type={btnType}
    className={`
  ${
    isDisabled
      ? "pointer-events-none bg-gray-100 text-gray-400"
      : "text-violet-800 hover:text-white bg-gray-100 hover:bg-indigo-500"
  }
  group inline-flex group items-center  transition font-medium rounded text-sm13 px-2.5 py-0.5
  `}
    disabled={isDisabled}
    {...rest}
  >
    {children}
  </button>
);
